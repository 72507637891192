import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

import { useUniversalData } from '@hooks/useUniversalData/useUniversalData';

import { TextTypes } from './Text.types';
import { animations } from './Text.animations';

const Text = (props: TextTypes.Props) => {

    const {
            decoration,
            isSmall,
            isMedium,
            animate,
            children,
            delay } = props;


    const ref = useRef<HTMLHeadingElement>(null);
    const { isMapLoaded } = useUniversalData();

    useEffect(() => {

        if (!isMapLoaded) {
            return;
        }

        const children = [...ref.current!.children] as HTMLElement[];
        const timeline = gsap.timeline({ delay: delay ?? 0 });

        const animateItem = (el: HTMLElement): void => {
            const animationType: 'fadeUp' | 'split' | 'scaleY' | 'scaleX' | undefined = el.dataset.animation as 'fadeUp' | 'split' | 'scaleY' | 'scaleX' | undefined;

            if (animationType && animations.hasOwnProperty(animationType)) {
                animations[animationType](timeline, el);
            }
        }

        children.forEach((el: HTMLElement) => {
            animateItem(el);

            if(!!el.children.length) {
                [...el.children].forEach(child => {
                    animateItem(child as HTMLElement);
                })
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMapLoaded])


    const className = `text${isSmall ? ' text--small' : isMedium ? ' text--medium' : ''}${animate ? ' is-animated' : ''} ${decoration ? ' is-decoration' : ''}`;

    return (
            <div ref={ref} className={className}>
                {children}
            </div>
        );
 };


export default Text;
