/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

import Section from '@components/universal/Section/Section';
import Text from '@components/molecules/Text/Text';
import Grid from '@components/scaffold/Grid/Grid';
import Button from '@components/atoms/Button/Button';
import Modal from '@components/molecules/Modal/Modal';

import { content } from '@data/home';

import { useNavigate } from 'react-router-dom';
import { useBreakpoint } from '@hooks/useBreakpoint/useBreakpoint';
import { useMapActions } from '@hooks/useMapActions/useMapActions';
import { useUniversalData } from '@hooks/useUniversalData/useUniversalData';


const Home = () => {

    const wrapperRef = useRef<HTMLDivElement>(null);

    const { setVisible, setVisibleMobile } = useMapActions();
    const { isMapLoaded } = useUniversalData();
    const { desktop } = useBreakpoint();
    const navigate = useNavigate();

    const { title } = content;

    const [isModalVisible, setModalVisible] = useState<boolean>(false);


    useEffect(() => {
        if (!wrapperRef.current) return;

        gsap.fromTo(wrapperRef.current.children,
            { y: -32, opacity: 0 },
            { duration: 1, stagger: 0.2, ease: 'custom', y: 0, opacity: 1, delay: 1 }
        )
    }, [isMapLoaded, wrapperRef.current]);

    
    useEffect(() => {
        setVisible(true);
        setVisibleMobile(false);
    }, []);

    return (
        <Section title="Homepage" center fullHeight>
            <Grid />

            <div className={`section__wrapper${isModalVisible ? ' is-modal-open' : ''}`}>
                <video width="600" height="100%" autoPlay loop muted playsInline>
                    <source
                        src="https://nuclear-site-assets.s3.amazonaws.com/page_videos/6493011d4d9da.mov"
                        type='video/mp4; codecs="hvc1"' />
                    <source
                        src="https://nuclear-site-assets.s3.amazonaws.com/page_videos/6493011d4d9da.webm"
                        type="video/webm" />
                </video><div className='section__inner'>
                    <Text delay={0.5}>
                        <h2 style={{ textAlign: 'center' }} data-animation="split">{title}</h2>
                    </Text>
                </div>
                <div ref={wrapperRef} className='section__button'>
                    <Button route='/map' text={`${desktop ? 'Explore' : 'View map'}`} icon='explore' />
                </div>
            </div>
        </Section>
    );
}



export default memo(Home);
