import { lazy, Suspense, FC, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { gsap } from 'gsap';
import dayjs from 'dayjs';
import toObject from 'dayjs/plugin/toObject';

import { SplitText } from 'gsap/SplitText';
import { Draggable } from 'gsap/Draggable';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';

/**
 * HOC
 *
 */
import { withRoot } from '@hoc/root/withRoot';
import HomePage from '@pages/HomePage';

/**
 *  Pages
 */
const MapPage = lazy(() => import('@pages/MapPage'));
const AboutPage = lazy(() => import('@pages/AboutPage'));
const ListPage = lazy(() => import('@pages/ListPage'));
const TestPage = lazy(() => import('@pages/TestPage'));
const MethodologyPage = lazy(() => import('@pages/MethodologyPage'));
const SocietalPage = lazy(() => import('@pages/SocietalPage'));
const TrinityPage = lazy(() => import('@pages/TrinityPage'));
const FaqsPage = lazy(() => import('@pages/FaqsPage'));
const Glossary = lazy(() => import('@pages/Glossary'));
const NevadaPage = lazy(() => import('@pages/NevadaPage'));
const NotFoundPage = lazy(() => import('@pages/NotFoundPage'));


gsap.registerPlugin(SplitText, Draggable, CSSRulePlugin);
dayjs.extend(toObject);



const Root = () => {
  document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);

  useEffect(() => {
    document.body.classList.add('is-extra-visible');
    document.body.classList.add('is-conus');
  }, [])

  const location = useLocation();

//   const { data, isSuccess } = useGetPageQuery({ id: location.pathname.slice(0, 1) });

  return (
            <>
                <Suspense fallback={null}>

                    <AnimatePresence exitBeforeEnter>

                        <Routes location={location} key={location.pathname}>

                            <Route path='*' element={<NotFoundPage />} />
                            <Route path='/' element={<HomePage />} />
                            <Route path='/map' element={<MapPage />} />
                            <Route path='/list' element={<ListPage />} />
                            <Route path='/methodology' element={<MethodologyPage />} />
                            <Route path='/societal-impacts' element={<SocietalPage />} />
                            <Route path='/trinity' element={<TrinityPage />} />
                            <Route path='/faqs' element={<FaqsPage />} />
                            <Route path='/glossary' element={<Glossary />} />
                            <Route path='/nevada' element={<NevadaPage />} />
                            <Route path='/about' element={<AboutPage />} />
                            <Route path='/depositions/:test' element={<TestPage />} />

                        </Routes>

                    </AnimatePresence>

                </Suspense>

            </>
    );
};

const composedRoot: FC = withRoot(Root) as FC;

export default composedRoot;
