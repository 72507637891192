export const routes = [
    {
        pathname: '/',
        name: 'Home'
    },
    {
        pathname: '/map',
        name: 'Deposition Map'
    },
    {
        pathname: '/list',
        name: 'Radioactive Plume Trajectories'
    },
    {
        pathname: '/about',
        name: 'About'
    },
]

export const textPages = [
    {
        pathname: '/methodology',
        name: 'Methodology'
    },
    {
        pathname: '/trinity',
        name: 'Trinity'
    },
    {
        pathname: '/nevada',
        name: 'Nevada'
    },
    {
        pathname: '/societal-impacts',
        name: 'Societal Impacts'
    },

    {
        pathname: '/faqs',
        name: 'FAQs'
    },
    {
        pathname: '/glossary',
        name: 'Glossary'
    },

]
