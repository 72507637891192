import { FC } from 'react';
import { compose } from '@reduxjs/toolkit';

import { withRouteChange } from './withRouteChange';
import { withLayout } from './withLayout';
import { withDetection } from './withDetection';

export const withRoot = (Component: FC<any>) => compose(
    withRouteChange,
    withLayout,
    withDetection
)(Component);
