import { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

import { useUniversalData } from '@hooks/useUniversalData/useUniversalData';

import Grid from '../Grid/Grid';
import LogoIcon from '@components/atoms/LogoIcon/LogoIcon';


const Loader = () => {

    const [isVisible, setIsVisible] = useState<boolean>(true);

    const loaderRef = useRef<HTMLDivElement>(null);
    const progressRef = useRef<HTMLDivElement>(null);
    const counterRef = useRef<HTMLSpanElement>(null);
    const tweenRef = useRef<GSAPTween>();

    const { isMapLoaded, isGridLoaded } = useUniversalData();

    const animateOut = (el: HTMLDivElement) => {
        gsap.to(el, { opacity: 0, duration: 1, ease: 'sine.out', onComplete: () => setIsVisible(false) })
    }


    useEffect(() => {
        const counter = counterRef.current!;
        const loader = loaderRef.current!;
        const progress = progressRef.current!;

        const toLoad = [isMapLoaded, isGridLoaded];
        const toLoadProgress = toLoad.filter(el => el === true).length / toLoad.length;

        gsap.killTweensOf(counter);

        // const shortTime = 0.35;

        if (!tweenRef.current) {
            tweenRef.current = gsap.to(counter, {
                ease: 'none',
                textContent: '100%',
                roundProps: 'textContent',
                onComplete: () => {
                    document.body.classList.add('is-loaded');
                    animateOut(loader);
                },
                onUpdate: () => {
                    const percent = gsap.getProperty(counter, 'textContent') as number / 100;
                    gsap.set(progress, { scaleX: percent });
                }
            });

            tweenRef.current.pause();
        }

        gsap.to(tweenRef.current, { progress: toLoadProgress, duration: 1 });


    }, [isMapLoaded, isGridLoaded]);


    return (
        <>
            {isVisible && (
                <div className="loader" ref={loaderRef}>
                    <Grid />
                    <div className="loader__content">
                        <div className="loader__logotype">
                            <LogoIcon />
                        </div>
                        <div className="loader__counter">
                            Loading <span ref={counterRef}>0%</span>
                        </div>
                    </div>
                    <div className="loader__progress">
                        <div className="loader__progress-bar" ref={progressRef}></div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Loader;