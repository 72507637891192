import { AppDispatch } from '@redux/store';

import mapSlice from '@redux/slices/map.slice';
import { MapSliceTypes } from '@redux/types/map.types';
import { InternMap } from 'd3-array';
import { IPoints } from '@components/organisms/Map/Map.types';


export const mapActions = (dispatch: AppDispatch) => ({
    setPoints: (item: IPoints) => dispatch(mapSlice.actions.updatePoints(item)),
    setCurrent: (item: MapSliceTypes.CurrentItem) => dispatch(mapSlice.actions.updateCurrent(item)),
    setCurrentCoords: (item: MapSliceTypes.CurrentItem) => dispatch(mapSlice.actions.updateCurrentCoords(item)),
    setLoaded: (isLoaded: boolean) => dispatch(mapSlice.actions.updateLoaded(isLoaded)),
    setMarker: (isVisible: boolean) => dispatch(mapSlice.actions.updateMarker(isVisible)),
    setExtended: (isExtended: boolean) => dispatch(mapSlice.actions.updateExtended(isExtended)),
    setVisibleMobile: (isVisibleMobile: boolean) => dispatch(mapSlice.actions.updateVisibleMobile(isVisibleMobile)),
    setFiltersVisible: (isFiltersVisible: boolean) => dispatch(mapSlice.actions.updateFiltersVisible(isFiltersVisible)),
    setVisible: (isVisible: boolean) => dispatch(mapSlice.actions.updateVisible(isVisible)),
    setLayer: (layer: MapSliceTypes.Layer) => dispatch(mapSlice.actions.updateLayer(layer)),
    setPopup: (content: MapSliceTypes.IPopup) => dispatch(mapSlice.actions.updatePopup(content)),
    setPopupValues: (values: MapSliceTypes.IPopupValues) => dispatch(mapSlice.actions.updatePopupValues(values)),
    setLayers: (layers: MapSliceTypes.Layer[]) => dispatch(mapSlice.actions.updateLayers(layers)),
    setItems: (items: MapSliceTypes.ApiDataItem[]) => dispatch(mapSlice.actions.updateItems(items)),
    setItemsExtedned: (items: MapSliceTypes.ApiDataItem[]) => dispatch(mapSlice.actions.updateItemsExtedned(items)),
    setItemsTotal: (items: MapSliceTypes.ApiDataItem[]) => dispatch(mapSlice.actions.updateItemsTotal(items)),
    setItemsTotalExtended: (items: MapSliceTypes.ApiDataItem[]) => dispatch(mapSlice.actions.updateItemsTotalExtended(items)),
    setFiltersRange: (range: MapSliceTypes.FilterRange) => dispatch(mapSlice.actions.updateFiltersRange(range)),
    setCurrentDateMin: (min: MapSliceTypes.FilterItem) => dispatch(mapSlice.actions.updateFiltersCurrentDateMin(min)),
    setCurrentDateMax: (max: MapSliceTypes.FilterItem) => dispatch(mapSlice.actions.updateFiltersCurrentDateMax(max)),
    setCalendarMin: (min: MapSliceTypes.FilterItem) => dispatch(mapSlice.actions.updateCalendarCurrentDateMin(min)),
    setCalendarMax: (max: MapSliceTypes.FilterItem) => dispatch(mapSlice.actions.updateCalendarCurrentDateMax(max)),
    toggleCalendarClick: (isChanged: boolean) => dispatch(mapSlice.actions.toggleCalendarClick(isChanged)),
    toggleCalendarSet: (isSet: boolean) => dispatch(mapSlice.actions.toggleCalendarSet(isSet)),
    setToggleDropdown: (toggleDropdown: boolean) => dispatch(mapSlice.actions.updateDropdown(toggleDropdown)),
    setItemsFiltered: (itemsF: MapSliceTypes.ApiDataItem[]) => dispatch(mapSlice.actions.updateItemsFiltered(itemsF)),
    setRangeLoaded: (isLoaded: boolean) => dispatch(mapSlice.actions.updateRangeLoaded(isLoaded)),
    setSliderRange: (range: {min:number,max:number,difference:number}) => dispatch(mapSlice.actions.updateSliderRange(range)),
    setSingleTest: (isSingleTest: boolean) => dispatch(mapSlice.actions.updateSingleTest(isSingleTest)),
    setSortedData: (data: InternMap<any, GeoJSON.Feature[]>) => dispatch(mapSlice.actions.updateData(data)),
    setCurrentTime: (data: MapSliceTypes.CurrentTime) => dispatch(mapSlice.actions.updateCurrentTime(data)),
    setInputRangeValue: (val: number) => dispatch(mapSlice.actions.updateInputRangeValue(val)),
    setAutoplay: (isAutoplay: boolean) => dispatch(mapSlice.actions.updateAutoplay(isAutoplay)),
    setCurrentTest: (test: MapSliceTypes.ApiDataItem | null) => dispatch(mapSlice.actions.updateCurrentTest(test)),
    setMapState: (map: MapSliceTypes.InitialState['mapState']) => dispatch(mapSlice.actions.updateMapState(map)),
    setMapClickMobile: (val: boolean) => dispatch(mapSlice.actions.updateMapClickMobile(val)),
    setCanZoom: (canZoom: boolean) => dispatch(mapSlice.actions.updateCanZoom(canZoom)),
    setGridFilter: (range: MapSliceTypes.GridRange) => dispatch(mapSlice.actions.updateGridRange(range)),
    setGridFeatures: (features: mapboxgl.MapboxGeoJSONFeature[]) => dispatch(mapSlice.actions.updateGridFeatures(features)),
    setGridDepositions: (depositons: MapSliceTypes.IGridDepositions) => dispatch(mapSlice.actions.updateGridDepositions(depositons)),
    setTriggerReset: (trigger: boolean) => dispatch(mapSlice.actions.updateTriggerReset(trigger)),
});