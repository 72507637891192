
import {useState, useEffect} from 'react';
import throttle from 'lodash.throttle';

const getDeviceConfig = (width: number) => {
  if(width < 720) {
    return 'phone';
  } else if(width >= 720 && width < 1024) {
    return 'tablet';
  } else if(width >= 1024) {
    return 'desktop';
  } else if(width >= 1800) {
    return 'hd';
  }
};

export const useBreakpoint = () => {
  const [breakpointValue, setBreakpointValue] = useState(() => getDeviceConfig(window.innerWidth));
  const [breakpoint, setBreakpoint] = useState({
    desktop: true,
    tablet: false,
    phone: false,
    value: 'desktop',
});

  useEffect(() => {
    const calcInnerWidth = throttle(function() {
        setBreakpointValue(getDeviceConfig(window.innerWidth))
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  useEffect(() => {
    setBreakpoint({
        desktop: breakpointValue === 'desktop',
        tablet: breakpointValue === 'tablet',
        phone: breakpointValue === 'phone',
        value: breakpointValue!,
    })
  }, [breakpointValue]);

  return breakpoint;
}
