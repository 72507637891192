/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect } from "react";
import { MapZoomTypes } from "./MapZoom.types";
import { useUniversalData } from "@hooks/useUniversalData/useUniversalData";
import { useBreakpoint } from '@hooks/useBreakpoint/useBreakpoint';
import { mobileZoom } from "@data/map";
import { useMapData } from "@hooks/useMapData/useMapData";



const MapZoom = ({ mapRef }: MapZoomTypes.Props) => {
    const {
        currentLayer,
    } = useMapData(({ map }) => map);
    const {
        isMapLoaded,
    } = useUniversalData();
    const { desktop } = useBreakpoint();

    const onZoomEnd = () => {
        const map = mapRef.current!;
        const isZoomHigh = mobileZoom <= map.getZoom();
        document.body.classList.toggle('is-zoom-high', isZoomHigh);
    }


    // on map loaded
    useEffect(() => {
        const map = mapRef.current!;

        if (!desktop) {
            map.on('zoomend', onZoomEnd);
        }
        
        onZoomEnd();
        
        return () => {
            map.off('zoomend', onZoomEnd);
            document.body.classList.remove('is-zoom-high');
        }
    }, [isMapLoaded, desktop]);

    // check if currently is heavy layer (for mobile)
    useEffect(() => {
        const isHeavyLayer = currentLayer.id === 'zip' || currentLayer.id === 'counties';
        document.body.classList.toggle('is-heavy-layer', isHeavyLayer);
    }, [currentLayer])

    return null;
}

export default memo(MapZoom);