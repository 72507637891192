//import { ReactComponent as SVGArrow } from '@svg/arrow.svg';
import { ReactComponent as SVGClose } from '@svg/close.svg';

const Arrow = () => (
    <span className='arrow'>
       <SVGClose />
    </span>
);

export default Arrow;
