import { useAppDispatch } from '@redux/store';
import { universalActions } from '@redux/actions/universal.actions';

export const useUniversalActions = () => {

    const dispatch = useAppDispatch();

    const data = universalActions(dispatch);

    return data;

}