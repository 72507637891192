import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import api from '@utils/api';

export const plumes = createApi({
    reducerPath: 'plumes',
    baseQuery: fetchBaseQuery({
        baseUrl: api.plumesUrl
    }),
    endpoints: (builder) => ({
        getPlumes: builder.query<GeoJSON.FeatureCollection, { id: string }>({
            query: arg => {
                return arg.id.length > 0 ? `/plume-data/era5_plumes/compiled_test_${arg.id}_first_120_simplified.geojson` : '';
            }
        }),
    })
});

export const { useGetPlumesQuery } = plumes;
