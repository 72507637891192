import { MapSliceTypes } from '@redux/types/map.types';

export const formatData = (data: MapSliceTypes.ApiDataItem[]): MapSliceTypes.ApiDataItem[] => {


    const formated = data.map(item => {
        const tempItem = JSON.parse(JSON.stringify(item));
        const dateArrFirst = item.first_hour.split('-');
        const yearFirst = parseFloat(dateArrFirst[0]);
        const monthFirst = parseFloat(dateArrFirst[1]) - 1;
        const dayFirst = parseFloat(dateArrFirst[2].split(' ')[0]);
        const hourFirst = parseFloat(dateArrFirst[2].split(' ')[1].split(':')[0]);

        const dateArrLast = item.last_hour.split('-');
        const yearLast = parseFloat(dateArrLast[0]);
        const monthLast = parseFloat(dateArrLast[1]) - 1;
        const dayLast = parseFloat(dateArrLast[2].split(' ')[0]);
        const hourLast = parseFloat(dateArrLast[2].split(' ')[1].split(':')[0]);

        tempItem.first_year = yearFirst;
        tempItem.first_month = monthFirst;
        tempItem.first_day = dayFirst;
        tempItem.first_hour_index = hourFirst;
        tempItem.last_year = yearLast;
        tempItem.last_month = monthLast;
        tempItem.last_day = dayLast;
        tempItem.last_hour_index = hourLast;

        return tempItem;
    });

    const sorted = formated.sort(function(a, b) {
        var textA = a.first_hour.toUpperCase();
        var textB = b.first_hour.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });


    return sorted;
}