
import { gsap } from 'gsap';
import { SplitText } from 'gsap/all';

const split = (tl: ReturnType<typeof gsap.timeline>, el: HTMLElement) => {
    
    const split = new SplitText(el, { type: 'words, lines' });
    gsap.set(split.lines, { clipPath: 'inset(0 0 -100px 0)' });
    
    tl.fromTo(split.words, { yPercent: -120 }, {  stagger: 0.045, yPercent: 0, duration: 0.65 }, '>-0.5');
};


const fadeUp = (tl: ReturnType<typeof gsap.timeline>, el: HTMLElement) => {
    tl.fromTo(el, { y: -40, opacity: 0 }, { opacity: 1, y: 0, duration: 0.85 }, '>-0.5');
    
};

const scaleY = (tl: ReturnType<typeof gsap.timeline>, el: HTMLElement) => {
    tl.fromTo(el, { scaleY: 0 }, { scaleY: 1, duration: 0.75, ease: 'custom' }, '>-0.5');
};

const scaleX = (tl: ReturnType<typeof gsap.timeline>, el: HTMLElement) => {
    tl.fromTo(el, { scaleX: 0 }, { scaleX: 1, transformOrigin: 'left', duration: 0.75, ease: 'custom' }, '>-0.9');
};


export const animations = {
    fadeUp,
    split,
    scaleY,
    scaleX
}