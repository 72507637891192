export const c = {
    black: '#000000',
    white: '#ffffff',
    yellow: '#ffffa6',
    orange: '#f4b650',
    brownBright: '#bd5422',
    brown: '#5d0e07',
    transparent: 'transparent'
};

export const colors = [
    { breakpoint: 1000, color: c.black },
    { breakpoint: 100, color: c.white },
    { breakpoint: 10, color: c.yellow },
    { breakpoint: 1, color: c.orange},
    { breakpoint: 0.1, color: c.brownBright },
    { breakpoint: 0.01, color: c.brown },
    { breakpoint: 0, color: c.transparent }
];