export const variants = {
     
    visible: {
        opacity: 1,
        transition: {
            duration: 0.2,
            when: 'beforeChildren' 
        }
    },

    hidden: {
        opacity: 0,
        transition: {
            duration: 0.2,
        }
    }
}