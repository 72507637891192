import { UnviversalSliceTypes } from '@redux/types/universal.types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import api from '@utils/api';

export const pages = createApi({
    reducerPath: 'pages',
    baseQuery: fetchBaseQuery({
        baseUrl: api.pagesUrl
    }),
    endpoints: (builder) => ({
        getPage: builder.query<UnviversalSliceTypes.IcsmContent, { id: string }>({
            query: arg => {
                return arg.id.length > 0 ? `/page?url=${arg.id}` : '';
            }
        }),
    })
});

export const { useGetPageQuery } = pages;
