import mapboxgl from 'mapbox-gl';
import { group, InternMap } from 'd3-array';

import { IUseMapInit, mapContainer, mapType } from './useMap.interfaces';
import { MapSliceTypes } from '@redux/types/map.types';



 export const formatData = (data: MapSliceTypes.ApiDataItem[]): MapSliceTypes.ApiDataItem[] => {

    const formated = data.map(item => {
        const tempItem = JSON.parse(JSON.stringify(item));
        const dateArrFirst = item.first_hour.split('-');
        const yearFirst = parseFloat(dateArrFirst[0]);
        const monthFirst = parseFloat(dateArrFirst[1]);

        const dateArrLast = item.first_hour.split('-');
        const yearLast = parseFloat(dateArrLast[0]);
        const monthLast = parseFloat(dateArrLast[1]);

        tempItem.first_year = yearFirst;
        tempItem.first_month = monthFirst;
        tempItem.last_year = yearLast;
        tempItem.last_month = monthLast;

        return tempItem;
    });

    const sorted = formated.sort(function(a, b) {
        var textA = a.first_hour.toUpperCase();
        var textB = b.first_hour.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });


    return sorted;
}



export const checkNoLine = (): boolean => {
    const amp = window.location.hash.split('&')[1];

    return amp === 'no-line';
}



export const init = ({ mapRef, containerRef, options, token, onLoad }: IUseMapInit): Promise<void> => {

    const { lng, lat, zoom } = options;

    return new Promise(resolve => {

        const container = containerRef.current! as HTMLElement;

        mapboxgl.accessToken = token;

        mapRef.current = new mapboxgl.Map({
        container,
            // style: 'mapbox://s/mikolaj-huncwot/clai3z66z000q15uh7n24d9yw',
            // style: 'mapbox://styles/mikolaj-huncwot/cld48ypn1000701p7zem1ox9x',
            style: 'mapbox://styles/earthrise/cljza2lwo002101r59kyw4gjq',
            center: [lng, lat],
            zoom: zoom,
            maxBounds: [
                [-157.92291722231334, 7.397370473380349],
                [-30.35742152642146, 60.78485244010895]
            ],
            minZoom: 0.2,
            maxZoom: 10,
            interactive: true,
            dragRotate: false,
            touchZoomRotate: true,
        });
        mapRef.current.touchZoomRotate.disableRotation();

        mapRef.current.on('load', () => {
           onLoad();
           resolve();
        });

        (window as any).map = mapRef.current;
    });

};


export const controls = new mapboxgl.NavigationControl({
    showCompass: false,
    visualizePitch: false,
});


export const destroy = ( mapRef : mapType, containerRef: mapContainer) => {
    mapRef.current?.remove();
    mapRef.current = null;
    if (containerRef?.current) {
        containerRef.current.innerHTML = '';
    }
}



export const sortData = (data: GeoJSON.FeatureCollection ):InternMap<any, GeoJSON.Feature[]> => {
    return group(data.features.sort(function(a, b) {
        return (a.properties!.formatedDate < b.properties!.formatedDate) ? -1 : (a.properties!.formatedDate > b.properties!.formatedDate) ? 1 : 0;
    }), ({ properties }: any) => properties!.formatedDate);
}



