import { AppDispatch } from '@redux/store';

import universalSlice from '@redux/slices/universal.slice';
import { UnviversalSliceTypes } from '@redux/types/universal.types';

export const universalActions = (dispatch: AppDispatch) => ({
    toggleMenu: () => dispatch(universalSlice.actions.toggleMenu()),
    clearSearchInput: () => dispatch(universalSlice.actions.clearSearchInput()),
    closeMenu: () => dispatch(universalSlice.actions.closeMenu()),
    setIsHeaderVisible: (isHeaderVisible: boolean) => dispatch(universalSlice.actions.setHeaderVisible(isHeaderVisible)),
    setIsModalVisible: (isModalVisible: boolean) => dispatch(universalSlice.actions.setModalVisible(isModalVisible)),
    setMapLoaded: () => dispatch(universalSlice.actions.setMapLoaded()),
    setGridLoaded: () => dispatch(universalSlice.actions.setGridLoaded()),
    setHowToFlag: () => dispatch(universalSlice.actions.setHowToFlag()),
    setAboutOpen: () => dispatch(universalSlice.actions.setAboutOpen()),
    setForceZoom: () => dispatch(universalSlice.actions.setForceZoom()),
    setForceZoomAddress: () => dispatch(universalSlice.actions.setForceZoomAddress()),
    setForceZoomConus: (forceZoomConus?: boolean) => dispatch(universalSlice.actions.setForceZoomConus(forceZoomConus as never)),
    setOrder: (newOrder: UnviversalSliceTypes.InitialState['order']) => dispatch(universalSlice.actions.updateOrder(newOrder)),
    setOrderList: (newOrder: UnviversalSliceTypes.InitialState['orderList']) => dispatch(universalSlice.actions.updateOrderList(newOrder)),
    setLiveSearchClicked: (isClicked: boolean) => dispatch(universalSlice.actions.updateLiveSearchClicked(isClicked)),
    setAutoFocus: (isAutoFocus: boolean) => dispatch(universalSlice.actions.updateAutoFocus(isAutoFocus)),
});