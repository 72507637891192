
const SvgStore = () => (
    <svg style={{ display: 'none' }} className="svg-sprite" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        
        <symbol id="sprite-warning-triangle" viewBox="0 0 21 16" preserveAspectRatio="xMidYMid meet">
            <path  fillRule="evenodd" clipRule="evenodd" d="M8.8279 2.54795C9.61775 1.34437 11.3822 1.34437 12.1721 2.54795L18.9674 12.9027C19.8402 14.2327 18.8861 16 17.2953 16H3.70471C2.11388 16 1.15979 14.2327 2.03261 12.9027L8.8279 2.54795ZM10.1818 4.44444H11.0833V11.5555H10.1818V4.44444ZM11.0833 13.3333H10.1818V14.2222H11.0833V13.3333Z" />
        </symbol>
        
        <symbol id="sprite-warning-oval" viewBox="0 0 16 16" preserveAspectRatio="xMidYMid meet">
            <circle cx="8" cy="8" r="8" />
        </symbol>

    </svg>
);

export default SvgStore;