import { MutableRefObject, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import axios from 'axios';

import { useMapActions } from '@hooks/useMapActions/useMapActions';
import { getCurrentPeriod, markerHtml, getPeriodIndex, getDate, getLocation, getVolume, getColor, getPoints } from './useMapMethods.utils';
import { getDataFromFeature } from '@utils/getDataFromFeature';

import secrets from '@secrets';
import { IUseMapMethods, MapboxGlEvent } from './useMapMethods.types';

import { IPoints } from '@components/organisms/Map/Map.types';
import { content } from '@data/map';
import api from '@utils/api';
import { useBreakpoint } from '@hooks/useBreakpoint/useBreakpoint';

export const useMapMethods = ({ mapRef, sortedData, timelineRef, animationRef, triggerMapClickMobile }: Partial<IUseMapMethods>) => {


    const {
        setCurrent,
        setCurrentTime,
        setInputRangeValue,
        setPopup,
        setPopupValues,
        setPoints,
        setMarker,
        setMapClickMobile
    } = useMapActions();

    const { desktop } = useBreakpoint();

    const createMarkerHTML = useCallback(() => {

        const element = document.createElement('div');
        element.classList.add('map__marker', 'js-map-marker');
        element.innerHTML = markerHtml;

        return element;

    }, []);


    /**
     * Add marker to mapbox
     * @param event - Mapbox MapMouse Event
     */
    const applyMarker = async (features: mapboxgl.MapboxGeoJSONFeature[] | undefined, lngLat: mapboxgl.LngLat, fromApi: boolean, type: string) => {

        if (fromApi) {

            if (!features) { return; }
            if (features.length < 1) { return; }

            // reset depositions table
            //document.body.classList.add('hide-depositions');
            const apiUrl = `${api.baseUrl}/areas/details?type=${type}&id=${features[0].properties!.area_id}`;
            const { data } = await axios.get(apiUrl);
            const nameFormated = data.features[0].properties!.state_abbr ? data.features[0].properties!.name + ', ' + data.features[0].properties!.state_abbr : data.features[0].properties!.name;
            setCurrent({
                name: nameFormated,
                coords: [null, null],
                id: features[0].properties!.area_id,
                type: type,
            });
        } else {
            const { lng, lat } = lngLat;

            // reset depositions table
            //document.body.classList.add('hide-depositions');
            const { data: { features } } = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?limit=1&country=us&access_token=${secrets.mapboxToken}`);

            if (!features[0]) {
                //document.body.classList.remove('hide-depositions');
                return;
            }

            const { place_name: name, center } = features[0];

            setCurrent({
                name,
                coords: center,
                id: null,
                type: 'address',
            });
        }

        if (!desktop) {
            setMapClickMobile(!triggerMapClickMobile);
        }
    };


    /**
     *  Remove marker from mapbox
     */
    const removeMarker = (markerRef: MutableRefObject<mapboxgl.Marker | null>) => {

        if (markerRef!.current) {
            markerRef!.current.remove();
            markerRef!.current = null;
            setMarker(false);

        }
    };

    /**
     * Attach click event to mapbox
     * @param event - Mapbox MapMouse Event
     */
    const applyClick = async (features: mapboxgl.MapboxGeoJSONFeature[] | undefined, lngLat: mapboxgl.LngLat, fromApi: boolean, type: string) => {
        applyMarker(features, lngLat, fromApi, type);
    };



    /**
     * Update heatmap with new data
     */
    const applyHeatmap = async (data: GeoJSON.FeatureCollection): Promise<GeoJSON.FeatureCollection> => {
           // console.log(data);
        // const unique = [...new Set((data as GeoJSON.FeatureCollection).features.map(item => item.properties!.CONC))];
        // console.log(unique);
        const localData: GeoJSON.FeatureCollection = JSON.parse(JSON.stringify(data))

        const formatedFeatures = localData.features.map(f => {
            const featureFormatted = f;
            if (f.properties!.CONC === '*******') { featureFormatted.properties!.fill = content.items[0].color; }
            if (f.properties!.CONC === '100.000') { featureFormatted.properties!.fill = content.items[1].color; }
            if (f.properties!.CONC === '10.000') { featureFormatted.properties!.fill = content.items[2].color; }
            if (f.properties!.CONC === '1.000') { featureFormatted.properties!.fill = content.items[3].color; }
            if (f.properties!.CONC === '0.100') { featureFormatted.properties!.fill = content.items[4].color; }
            if (f.properties!.CONC === '0.010') { featureFormatted.properties!.fill = content.items[5].color; }
            const date = f.properties!.DATE;
            const time = f.properties!.TIME;
            const y = date.slice(0,4);
            const m = date.slice(4,6);
            const d = date.slice(6,8);
            const H = time.slice(0,2);
            const M = time.slice(2,4);

            f.properties!.formatedDate = `${y}-${m}-${d}T${H}:${M}`;
            return featureFormatted;
        });

        const featureCollection: GeoJSON.FeatureCollection = { type: "FeatureCollection", "features": formatedFeatures };

        return featureCollection;
    };



    /**
     * Update heatmap with period's change
     * @param period - current period
     */
    const updateHeatmap = (period: any) => {
        const date = period[0];
        const layer = mapRef!.current?.getLayer('heatmap');

        layer && mapRef!.current?.setFilter('heatmap', [
            'match',
            ['get', 'formatedDate'],
            date,
            true,
            false
        ]);

    };



    /**
     * Update heatmap timeline
     * @param object
     */
    const applyTimeline = (amount: number) => {
        timelineRef!.current.clear();
        timelineRef!.current.fromTo(animationRef!.current, {
            value: 0,
        }, {
            value: amount - 1,
            roundProps: 'value',
            duration: 6.5,
            ease: 'none',
            onUpdate: () => {
                const value = animationRef!.current?.value ?? 0;
                updateCurrent(value);
                setInputRangeValue(value);
            },
        });

    };



    /**
     * Update current item
     * @param index
     * @returns
     */
    const updateCurrent = (index: number) => {
        if (!sortedData) return;

        const period = getCurrentPeriod(index, sortedData!);

        const periodIndex = getPeriodIndex(period, sortedData!);
        // const { volume, color } = getVolumeAndColor(period) ?? { volume: 1000, color: 'red' };
        const { pollution, color } = { pollution: 1000, color: 'red' };
        const date = getDate(period);

        setCurrentTime({
            period,
            pollution,
            color,
            date,
            periodIndex
        });

    };



    /**
     * Create autoplay for heatmap
     * @param object
     */
    const applyAutoplay = ({ timelineRef, isAutoplay, inputRangeValue }: any) => {
        (isAutoplay && sortedData)
            ? timelineRef!.current.progress(inputRangeValue / sortedData.size).play()
            : timelineRef!.current.pause();

    };



    /**
     * Create popup for existing marker
     * @param event - Mapbox MapMouse Event
     * @returns Promise
     */
    const applyPopup = async (event: MapboxGlEvent): Promise<void> => {

        const location = await getLocation(event);
        const volume = getVolume(event, mapRef!);
        const color = getColor(volume);
        const { lng, lat} = getPoints(event);

        setPoints(getPoints(event));
        setPopup({
            location,
            volume,
            color,
            changed: true,
            coords: [lng, lat]
        });
    };



    const updatePopupVolume = (points: IPoints) => {

        const bbox = [
            [points.x - 3, points.y - 3],
            [points.x + 3, points.y + 3]
        ];

        // Find features intersecting the bounding box.
        const selectedFeatures = mapRef!.current!.queryRenderedFeatures(bbox as [mapboxgl.PointLike, mapboxgl.PointLike], {
            layers: ['heatmap']
        });

        const { volume } = getDataFromFeature(selectedFeatures);
        const color = getColor(volume);

        setPopupValues({
            volume,
            color
        });

    };



    return {
        applyPopup,
        applyClick,
        applyHeatmap,
        applyTimeline,
        removeMarker,
        createMarkerHTML,
        updateHeatmap,
        updateCurrent,
        updatePopupVolume,
        applyAutoplay,
    };
};