import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { useDispatch } from 'react-redux';

// Slices
import universalSlice from './slices/universal.slice';
import mapSlice from './slices/map.slice';

// Services
import { mapApi } from './services/map';
import { mapApiProd } from './services/mapProd';
import { plumes } from './services/plumes';
import { pages } from './services/pages';


/**
 *  Main Reducer
 */
const reducer = combineReducers({
    universal: universalSlice.reducer,
    map: mapSlice.reducer,
    mapData: mapApi.reducer,
    mapDataProd: mapApiProd.reducer,
    plumes: plumes.reducer,
    pages: pages.reducer,
});


export const store = configureStore({
    reducer,
    devTools: true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat([mapApi.middleware, mapApiProd.middleware, plumes.middleware, pages.middleware])
});


setupListeners(store.dispatch);


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
// export const useAppSelector = <T>() => useSelector<RootState, T>;

export default store;


