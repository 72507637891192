import { useEffect } from 'react';
import { motion } from 'framer-motion';

import { variants } from './Section.variants';

import { ISection } from './Section.interfaces';

const Section = ({ children, title, fullHeight = false, center, overflow, bottom }: ISection) => {

    useEffect(() => {
        document.title = `Nuclear EM - ${title}`;
        
        document.body.classList.toggle('is-full-height', fullHeight);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const classNames = `section${fullHeight ? ' is-full' : ''}${center ? ' is-center' : ''}${overflow ? ' is-overflow' : ''}${bottom ? ' is-bottom': ''}`;
    
    return (
        <motion.div className={classNames} animate="visible" exit="hidden" initial="hidden" variants={variants}>
            <h1 className='sr-only'>{`Nuclear EM - ${title}`}</h1>
            {children}
        </motion.div>
    );   
}

export default Section;