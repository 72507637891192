/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

import { routes, textPages } from './Menu.data';


const Menu = () => {

    const menuRef = useRef<HTMLDivElement>(null);

    const setMenuHeightCssVar = () => {
        const menuHeight = menuRef.current?.getBoundingClientRect().height ?? 0;
        document.documentElement.style.setProperty('--menu-height', `${menuHeight}px`);
    }

    useEffect(() => {

        setMenuHeightCssVar();
        window.addEventListener('resize', setMenuHeightCssVar); 

        return () => {
            window.removeEventListener('resize', setMenuHeightCssVar);
        }

    }, []);


    return (
        <div className='menu' ref={menuRef}>
            <div className="menu__inner">
                <nav>
                    <ul className='menu__list'>
                        {routes.map(({ pathname, name }, index) => (
                            <li key={name}>
                                <NavLink to={pathname} className={({ isActive }) => isActive ? 'is-active' : ''}><span>{name}</span></NavLink>
                            </li>
                        ))}
                    </ul>
                </nav>
                <ul className='menu__links'>
                    {textPages.map(({ pathname, name }, index) => (
                        <li key={name}>
                            <NavLink to={pathname} className={({ isActive }) => isActive ? 'is-active' : ''}><span>{name}</span></NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Menu;
