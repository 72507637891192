export const content = {
    items: [
        {
            value: 1000,
            unit: 'pCi/m3/m3',
            color: '#000000',
        },
        {
            value: 100,
            unit: 'pCi/m3/m3',
            color: '#FFFFFF'
        },
        {
            value: 10,
            unit: 'pCi/m3/m3',
            color: '#FFFFA6'
        },
        {
            value: 1,
            unit: 'pCi/m3/m3',
            color: '#F4B650'
        },
        {
            value: 0.1,
            unit: 'pCi/m3/m3',
            color: '#BD5422'
        },
        {
            value: 0.01,
            unit: 'pCi/m3/m3',
            color: '#5D0E07'
        },
    ],
    buttons: [
        {
            text: 'Filters',
            icon: 'filters'
        },
        {
            text: 'Map',
            icon: 'globe'
        }
    ]
}

export const mobileZoom = 6.7;