/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, memo, useState } from 'react';

import { useMapData } from '@hooks/useMapData/useMapData';
import { MapLayersTypes } from './MapLayers.types';
import { usePrevious } from '@hooks/usePrevious/usePrevious';
import { checkNoLine } from '@hooks/useMap/useMap.utils';
import { useLocation } from 'react-router-dom';
import { mapLayerData } from '@data/mapLayerData';
import api from '@utils/api';
import { useUniversalActions } from '@hooks/useUniversalActions/useUniversalActions';
import { useUniversalData } from '@hooks/useUniversalData/useUniversalData';
import { mobileZoom } from '@data/map';
import { useBreakpoint } from '@hooks/useBreakpoint/useBreakpoint';
// import rasterGrid from '@assets/images/nuclear-grid.jpg';

const MapLayers = ({ mapRef, triggerHideLayers, triggerRestoreLayers, applyClick }: MapLayersTypes.Props) => {
    const { closeMenu, setLiveSearchClicked, setGridLoaded } = useUniversalActions();
    const [clickedId, setClickedId] = useState(null);
    const [clickedFeatures, setClickedFeatures] = useState<mapboxgl.MapboxGeoJSONFeature[]>([]);
    const [clickedLngLat, setClickedLngLat] = useState<mapboxgl.LngLat | null>(null);
    const [clickeLayerId, setClickedLayerId] = useState('');
    const {
        currentCoords,
        currentLayer,
        layers,
        rangeGrid,
    } = useMapData(({ map }) => map);
    const [gridFeatures, setGridFeatures] = useState<mapboxgl.MapboxGeoJSONFeature[]>([]);

    const {
        isMapLoaded,
    } = useUniversalData();
    const idCentroid = useMapData(({ map }) => map.currentCoords.id);
    const prevIdCentroid = usePrevious(idCentroid);
    const location = useLocation();
    const [hash, setHash] = useState(window.location.hash.replace('#', '').split('&')[0]);
    const [linesVisible, setLinesVisible] = useState(!checkNoLine());
    const currentItemId = useMapData(({ map }) => map.currentCoords.id);
    const { desktop } = useBreakpoint();


    const setLayer = (map: mapboxgl.Map) => {
        layers.forEach(layer => {
            if (!layer.isAvailable || !map) {return}
            const visibility = layer.id === currentLayer.id ? 'visible' : 'none';

            if (layer.id === 'street') {
                map.setLayoutProperty('street-click', 'visibility', visibility);
            } else {
                map.setLayoutProperty(layer.id, 'visibility', visibility);
                map.setLayoutProperty(layer.id + '-click', 'visibility', visibility);
            }
        });
        map!.setLayoutProperty('grid', 'visibility', 'visible');

        handleGridLayer(map);
        resetLayersStyle();
    }

    const handleGridLayer = (map: mapboxgl.Map): void => {
        document.body.classList.remove('is-grid');
        if (currentLayer.id === 'street' || currentLayer.id === 'conus') {
           if ( location.pathname === '/map' ) {
               map!.setLayoutProperty('grid', 'visibility', 'visible');
            //    map!.setLayoutProperty('grid-raster', 'visibility', 'visible');
               document.body.classList.add('is-grid');
           }
        }
    }

    const handleHashChange = (e: HashChangeEvent): void => {
        const newHash = e.newURL.split('#')[1].split('&')[0];

        setLinesVisible(!checkNoLine());
        setHash(() => newHash);
    }
    const restoreLayer = (map: mapboxgl.Map) => {
        if ( location.pathname !== '/map' ) {return}

        layers.forEach(layer => {
            if (!layer.isAvailable) {return}
            const visibility = layer.id === currentLayer.id ? 'visible' : 'none';


            if (layer.id === 'street') {
                // map!.setLayoutProperty('grid-raster', 'visibility', visibility);
                // map!.setLayoutProperty('grid', 'visibility', visibility);
                map!.setLayoutProperty('street-click', 'visibility', visibility);
            } else {
                map!.setLayoutProperty(layer.id, 'visibility', visibility);
                map!.setLayoutProperty(layer.id + '-click', 'visibility', visibility);
            }
        });
        document.body.classList.remove('is-grid');
        // if (currentLayer.id === 'conus') {
        //     if ( location.pathname === '/map' ) {
        //         map!.setLayoutProperty('grid', 'visibility', 'visible');
        //         // map!.setLayoutProperty('grid-raster', 'visibility', 'visible');
        //         document.body.classList.add('is-grid');
        //     }
        // }
        map!.setLayoutProperty('grid', 'visibility', 'visible');

    }

    const hideLayers = (map: mapboxgl.Map | null) => {
        if (!map ) { return }
        if (!map.getLayer('states')) { return }
        layers.forEach(layer => {
            if (!layer.isAvailable) {return}
            if (layer.id === 'street') {
                map!.setLayoutProperty('street-click', 'visibility', 'none');
            } else {
                map!.setLayoutProperty(layer.id, 'visibility', 'none');
                map!.setLayoutProperty(layer.id + '-click', 'visibility', 'none');
            }
        });
        // map!.setLayoutProperty('grid-raster', 'visibility', 'none');
        map!.setLayoutProperty('grid', 'visibility', 'none');
        map?.setLayoutProperty('country', 'visibility', 'none');
        resetLayersStyle();

    }


    const handleLayerChange = (map: mapboxgl.Map | null) => {
        if (!map) {
            return;
        }

        handleGridLayer(map);

        resetLayersStyle();
    }


    const resetLayersStyle = () => {
        mapRef.current?.setPaintProperty('states','line-width',1)
        mapRef.current?.setPaintProperty('counties','line-width',1)
        mapRef.current?.setPaintProperty('counties','line-color', '#c7c8cb')
        mapRef.current?.setPaintProperty('tribal','line-width',1)
        mapRef.current?.setPaintProperty('zip','line-width',1)
    }

    const centroidLayerClickHandler = (layerId: string, features: mapboxgl.MapboxGeoJSONFeature[] | undefined, lngLat: mapboxgl.LngLat) => {
        let apiLayerName = '';
        switch (layerId) {
            case 'states-click':
                apiLayerName = 'state'
                break;

            case 'zip-click':
                apiLayerName = 'zip'
                break;

            case 'counties-click':
                apiLayerName = 'county'
                break;

            case 'tribal-click':
                apiLayerName = 'tribal_land'
                break;
            default:
                break;
        }
        applyClick(features, lngLat, true, apiLayerName);
    }

    const addCustomSource = () => {

        mapRef.current!.addSource('customSource', {
            'type': 'vector',
            'tiles': [
                `${api.baseUrl}/tiles/all/{z}/{x}/{y}`
            ],
            'minzoom': 1,
            'maxzoom': 10
        });

        const featureCollection: GeoJSON.FeatureCollection = { type: "FeatureCollection", "features": [] };
        mapRef.current!.addSource('heatmap', {
            type: 'geojson',
            data: featureCollection,
        });

        // const leftX = -124.9;
        // const bottomY = 23.62;
        // const topY = 49.46;
        // const rightX = -66.6;
        // mapRef.current!.addSource('grid-raster', {
        //     'type': 'image',
        //     'url': rasterGrid,
        //     'coordinates': [
        //     [leftX, topY],
        //     [rightX, topY],
        //     [rightX, bottomY],
        //     [leftX, bottomY]
        //     ]
        // });
    }

    const removeCustomSource = () => {
        const map = mapRef.current;
        if (!map ) {
            return;
        }
        map.removeSource('customSource');
        map.removeSource('heatmap');
        // map.removeSource('grid-raster');
    }

    const addCustomLayers = () => {
        const minzoom = desktop ? 0 : mobileZoom;

        mapRef.current!.addLayer({
            'id': 'grid',

            'source': 'customSource',
            'source-layer': 'grid',
            'type': 'fill',
            paint: {
                "fill-color": [
                    "interpolate", ["linear"], ["get", "all_tests_last_dep"],
                      484.4382991554777,
                      "rgb(241, 250, 34)",
                      1727.6448911691143,
                      "rgb(248, 227, 37)",
                      6161.273530986445,
                      "rgb(253, 206, 38)",
                      21972.855485333836,
                      "rgb(255, 183, 45)",
                      78361.45818737331,
                      "rgb(253, 164, 56)",
                      279459.2688851957,
                      "rgb(248, 144, 68)",
                      996631.3130506858,
                      "rgb(241, 127, 79)",
                      3554270.996683877,
                      "rgb(232, 110, 91)",
                      12675542.251626736,
                      "rgb(222, 95, 102)",
                      45204592.312369786,
                      "rgb(210, 79, 115)",
                      161212445.634451,
                      "rgb(198, 64, 127)",
                      574929477.2497917,
                      "rgb(183, 48, 140)",
                      2050362194.493519,
                      "rgb(168, 33, 152)",
                      7312175310.123748,
                      "rgb(150, 18, 162)",
                      26077298883.864155,
                      "rgb(131, 5, 168)",
                      92999071854.42973,
                      "rgb(110, 0, 169)",
                      331661166453.7472,
                      "rgb(90, 1, 165)",
                      1182798141315.2212,
                      "rgb(67, 3, 158)",
                      4218194906740.2793,
                      "rgb(44, 5, 149)",
                      15043283929635.246,
                      "rgb(13, 8, 135)"
                ],
            },
            layout: {
                visibility: 'none',
            },

        }, "road-intersection");

        // borders
        mapRef.current!.addLayer({
            'id': 'states',
            'source': 'customSource',
            'source-layer': 'states',
            'type': 'line',
            'paint': {
                'line-color': '#c7c8cb',
                'line-opacity': 0.8
            }
        });
        mapRef.current!.addLayer({
            'id': 'counties',
            'source': 'customSource',
            'source-layer': 'counties',
            'type': 'line',
            'paint': {
                'line-color': checkNoLine() ? 'rgba(0, 0, 0, 0)' : '#c7c8cb',
                'line-opacity': 0.8
            },
            minzoom,
        });
        mapRef.current!.addLayer({
            'id': 'tribal',
            'source': 'customSource',
            'source-layer': 'tribal_lands',
            'type': 'line',
            'paint': {
                'line-color': '#c7c8cb',
                'line-opacity': 0.8
            }
        });
        mapRef.current!.addLayer({
            'id': 'zip',
            'source': 'customSource',
            'source-layer': 'zips',
            'type': 'line',
            'paint': {
                'line-color': '#c7c8cb',
                'line-opacity': 0.8
            },
            minzoom,
        });


        // for click
        mapRef.current!.addLayer({
            'id': 'states-click',
            'source': 'customSource',
            'source-layer': 'states',
            'type': 'fill',
            'paint': {
                'fill-color': 'rgba(0,0,0,0)'
            }
        });
        mapRef.current!.addLayer({
            'id': 'counties-click',
            'source': 'customSource',
            'source-layer': 'counties',
            'type': 'fill',
            'paint': {
                'fill-color': mapLayerData[hash] || 'rgba(0,0,0,0)'
            },
            minzoom,
        }, 'counties');
        mapRef.current!.addLayer({
            'id': 'tribal-click',
            'source': 'customSource',
            'source-layer': 'tribal_lands',
            'type': 'fill',
            'paint': {
                'fill-color': 'rgba(0,0,0,0)'
            }
        });
        mapRef.current!.addLayer({
            'id': 'zip-click',
            'source': 'customSource',
            'source-layer': 'zips',
            'type': 'fill',
            'paint': {
                'fill-color': 'rgba(0,0,0,0)'
            },
            minzoom,
        });
        mapRef.current!.addLayer({
            'id': 'street-click',
            source: 'composite',
            'source-layer': 'country_boundaries',
            'type': 'fill',
            'paint': {
                'fill-color': 'rgba(0,0,0,0)',
            }
        });

        //heatmap (animation single test)
        mapRef!.current?.addLayer({
            id: 'heatmap',
            source: 'heatmap',
            type: 'fill',
            paint: {
                'fill-color': ['case', ['has', 'fill'], ['get', 'fill'], 'rgba(0, 0, 0, 0)'],
            },
        });

        // min: 137.83999532020718 max: 15043283929635.225
        // setTimeout(() => {
        //     const f = mapRef.current!.queryRenderedFeatures(null!, {layers: ['grid']});
        //     let min = f[0].properties!.all_tests_last_dep;
        //     let max = f[0].properties!.all_tests_last_dep;
        //     f.forEach(f => {
        //         min = Math.min(min, f.properties!.all_tests_last_dep);
        //         max = Math.max(max, f.properties!.all_tests_last_dep);
        //     });
        //     console.log(f);

        //     console.log(min, max);
        // }, 4000);

        // grid raster for better performanece
        // mapRef!.current?.addLayer({
        //     id: 'grid-raster',
        //     'type': 'raster',
        //     'source': 'grid-raster',
        // });
    }

    const removeCustomLayers = () => {
        const map = mapRef.current;
        if (!map ) {
            return;
        }
        // borders
        map.removeLayer('states');
        map.removeLayer('counties');
        map.removeLayer('tribal');
        map.removeLayer('zip');
        // map.removeLayer('grid-raster');
        map.removeLayer('grid');

        // for click
        map.removeLayer('states-click');
        map.removeLayer('counties-click');
        map.removeLayer('tribal-click');
        map.removeLayer('zip-click');
        map.removeLayer('street-click');

        //heatmap (animation single test)
        map.removeLayer('heatmap');
    }

    const onClick = (event: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
    } & mapboxgl.EventData, layerId: string) => {
        const isMenuOpen = document.body.classList.contains('is-menu-open');
        // console.log(event.features![0], layerId);
        if (isMenuOpen) {
            closeMenu()
        } else {
            setLiveSearchClicked(false);

            if (layerId === 'street-click') {
                applyClick(event.features, event.lngLat, false, 'street')
            } else {
                setClickedId(event.features![0].properties!.area_id)
                setClickedFeatures(event.features!);
                setClickedLngLat(event.lngLat);
                setClickedLayerId(layerId);
            }
        }
    }

    const onGridLoaded = () => {
        const f = mapRef.current!.queryRenderedFeatures(undefined, {layers: ['grid']});
        if (gridFeatures.length === 0) {
            setGridLoaded();
            setGridFeatures(f);
        }
    }

    // on map loaded
    useEffect(() => {

        addCustomSource();
        addCustomLayers();
        hideLayers(mapRef.current);


        // Add event handlers
        window.addEventListener('hashchange', handleHashChange);
        layers.forEach(layer => {
            if (!layer.isAvailable) { return }
            let layerName = layer.id + '-click';
            if (layer.id === 'street') {
                layerName = 'street-click';
            }

            mapRef.current!.on('click', layerName, (e) => {
                onClick(e, layerName);
            });
        });

        mapRef.current!.on('idle', onGridLoaded);

        return () => {
            removeCustomLayers();
            removeCustomSource();
            window.removeEventListener('hashchange', handleHashChange);
            mapRef.current!.off('idle', onGridLoaded);
        }
    }, [isMapLoaded]);

    useEffect(() => {

        const map = mapRef.current;
        if (!currentLayer || !map || !isMapLoaded) {
            return;
        }
        document.body.classList.toggle('is-init-aggregtaion', currentLayer.id.length === 0);

        setLayer(map!);
        handleLayerChange(map);

        return () => {
            hideLayers(map);
        }

    }, [currentLayer]);


    // update layers on breakpoint change
    useEffect(() => {
        const map = mapRef.current!;
        if (!map || !isMapLoaded) { return; }
        const minzoom = desktop ? 0 : mobileZoom;

        map.setLayerZoomRange('counties', minzoom, 10.1);
        map.setLayerZoomRange('counties-click', minzoom, 10.1);
        map.setLayerZoomRange('zip', minzoom, 10.1);
        map.setLayerZoomRange('zip-click', minzoom, 10.1);

    }, [desktop]);



    // update style when active shape change
    useEffect(() => {

        if (prevIdCentroid === idCentroid) {
            return;
        }

        switch (currentCoords.type) {
            case 'state':
                mapRef.current?.setPaintProperty(
                    'states',
                    'line-width',
                    ['match', ['get', 'area_id'], currentCoords.id, 4, 1]
                )
                break;

            case 'county':
                mapRef.current?.setPaintProperty(
                    'counties',
                    'line-width',
                    ['match', ['get', 'area_id'], currentCoords.id, checkNoLine() ? 1 : 3, 1]
                );
                mapRef.current?.setPaintProperty(
                    'counties',
                    'line-color',
                    ['match', ['get', 'area_id'], currentCoords.id, '#9fa1a6', checkNoLine() ? 'rgba(0,0,0,0)' : '#c7c8cb']
                );
                break;

            case 'tribal_land':
                mapRef.current?.setPaintProperty(
                    'tribal',
                    'line-width',
                    ['match', ['get', 'area_id'], currentCoords.id, 3, 1]
                )
                break;

            case 'zip':
                mapRef.current?.setPaintProperty(
                    'zip',
                    'line-width',
                    ['match', ['get', 'area_id'], currentCoords.id, 3, 1]
                )
                break;

            default:
                break;
        }

        return () => {
        }

    }, [idCentroid]);

    // update style on hash chage
    useEffect(() => {
        const map = mapRef.current!;
        const isMapLoaded = map.isStyleLoaded();

        if (isMapLoaded) {
            map.removeLayer('counties-click');
            mapRef.current!.addLayer({
                'id': 'counties-click',
                'source': 'customSource',
                'source-layer': 'counties',
                'type': 'fill',
                'paint': {
                    'fill-color': mapLayerData[hash]
                }
            }, 'counties');

            map.setPaintProperty('counties', 'line-color', linesVisible ? '#c7c8cb' : 'rgba(0,0,0,0)');
        }

        if (isMapLoaded && currentLayer.id !== 'counties') {
            map.setLayoutProperty('counties-click', 'visibility', 'none');
        }

    }, [hash, linesVisible]);


    useEffect(() => {
        hideLayers(mapRef.current);
    }, [triggerHideLayers])

    // triger on map click
    useEffect(() => {
        if (currentItemId === clickedId) { return }
        // document.body.classList.add('hide-depositions');
        centroidLayerClickHandler(clickeLayerId, clickedFeatures!, clickedLngLat!);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickedId, clickeLayerId, clickedFeatures]);

    useEffect(() => {
        restoreLayer(mapRef.current!);
    }, [triggerRestoreLayers])


    useEffect(() => {
        const map = mapRef.current!;
        if (!map || !isMapLoaded) { return; }
        const filter = [
            'all',
            ['>=', [ 'log10', ['get', 'all_tests_last_dep']], rangeGrid.min],
            ['<=', [ 'log10', ['get', 'all_tests_last_dep']], rangeGrid.max],
        ]
        map.setFilter('grid', filter);


    }, [rangeGrid]);

    return null;
};


export default memo(MapLayers);