import { MapSliceTypes } from '@redux/types/map.types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import api from '@utils/api';

export const mapApiProd = createApi({
    reducerPath: 'mapDataProd',
    baseQuery: fetchBaseQuery({
        baseUrl: api.prodUrl
    }),
    endpoints: (builder) => ({
        getGridDepositions: builder.query<MapSliceTypes.IGridDepositions, { type: string | null, id: number | null }>({
            query: arg => arg.id ? `/deposition/area/all?type=${arg.type}&id=${arg.id}` : ''
        }),
    })
});

export const { useGetGridDepositionsQuery } = mapApiProd;
