import Hamburger from '@components/atoms/Hamburger/Hamburger';
import Arrow from '@components/atoms/Arrow/Arrow';

import { IToggle } from './Toggle.interfaces';


const Toggle = ({ onClick }: IToggle) => (
    <button className='toggle' onClick={onClick}>
        <Hamburger />
        <Arrow />
        <span className='sr-only'>toggle menu</span>
    </button>
);


export default Toggle;