import { ComponentType, useEffect } from 'react';
import { useLocation } from 'react-router-dom';



export function withRouteChange<P>(Component: ComponentType<P>) {
    const WrappedComponent = (props: any) => {

        const location = useLocation();

        useEffect(() => {
            document.body.classList.remove('is-menu-open');
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [location.pathname]);


        return (

            <Component {...props} />
        )
    }

    return WrappedComponent;

}