import { MapSliceTypes } from '@redux/types/map.types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import api from '@utils/api';
import { FeatureCollection } from 'geojson';

export const mapApi = createApi({
    reducerPath: 'mapData',
    baseQuery: fetchBaseQuery({
        baseUrl: api.baseUrl
    }),
    endpoints: (builder) => ({
        getData: builder.query<MapSliceTypes.ApiDataItem[], { lon:number|null, lat: number|null }>({
            query: (arg) => {
                const { lon, lat } = arg;
                const start_time = '1945-01-01T15:04:05';
                const end_time = '2006-01-02T15:04:05';
                return lon ? `deposition/point?lon=${lon}&lat=${lat}&start_time=${start_time}&end_time=${end_time}`: '';
            }
        }),
        getDataById: builder.query<MapSliceTypes.ApiDataItem[], { areaType: string | null, id: number | null }>({
            query: (arg) => {
                const { areaType, id } = arg;
                const start_time = '1945-01-01T15:04:05';
                const end_time = '2006-01-02T15:04:05';
                return id ? `deposition/area?type=${areaType}&id=${id}&start_time=${start_time}&end_time=${end_time}` : '';
            }
        }),
        getCoords: builder.query<FeatureCollection, { areaType: string | null, id: number | null }>({
            query: (arg) => {
                const { areaType, id } = arg;
                return id ? `areas/details?type=${areaType}&id=${id}` : '';
            }
        }),
        getAllMetadata: builder.query<MapSliceTypes.MetadataItem[], void>({
            query: () => '/metadata/test/all'
        }),
        getSingleMetadata: builder.query<MapSliceTypes.MetadataItem, { id: number }>({
            query: arg => `/metadata/test/${arg.id}`
        }),
        getGridDepositions: builder.query<MapSliceTypes.IGridDepositions, { type: string | null, id: number | null }>({
            query: arg => arg.id ? `/deposition/area/all?type=${arg.type}&id=${arg.id}` : ''
        }),
    })
});

export const { useGetDataQuery, useGetDataByIdQuery, useGetCoordsQuery, useGetAllMetadataQuery, useGetSingleMetadataQuery, useGetGridDepositionsQuery } = mapApi;
