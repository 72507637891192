/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useCallback, memo } from 'react';
import mapboxgl from 'mapbox-gl';

import { useMapData } from '@hooks/useMapData/useMapData';
import { usePrevious } from '@hooks/usePrevious/usePrevious';

import { MapMarkerTypes } from './MapMarker.types';

import * as MapMarkerUtils from './MapMarker.utils';
import { useMapActions } from '@hooks/useMapActions/useMapActions';


const MapMarker = ({ mapRef }: MapMarkerTypes.Props) => {

    const markerRef = useRef<mapboxgl.Marker | null>(null);
    const isTouching = useRef<boolean>(false);

    const { setMarker, setPopup } = useMapActions();
    const coords = useMapData<MapMarkerTypes.MapData>(({ map }) => map.current.coords);
    const { popup, isSingleTest } = useMapData(({ map }) => map);
    const prevCoords = usePrevious(coords);

    const createMarkerHTML = useCallback(() => {

        const element = document.createElement('div');
        element.classList.add('map__marker', 'js-map-marker');
        element.innerHTML = MapMarkerUtils.markerHtml;

        return element;

    }, []);

    const removeMarker = () => {

        if (markerRef.current && mapRef.current) {
            markerRef.current.remove();
            markerRef.current = null;
        }
        setMarker(false);

    }


    const addMarker = (coords: MapMarkerTypes.Coords) => {

        if (!mapRef.current) {
            return;
        }

        const element = createMarkerHTML();

        markerRef.current = new mapboxgl.Marker({
            anchor: 'bottom',
            element: element,
        })
            .setLngLat(coords)
            .addTo(mapRef.current);

    }


    const onInteraction = (): void => {
        removeMarker();
        setPopup({
            location: '',
            volume: 0,
            color: 'red',
            changed: false,
            coords: [null, null]
        });
    }


    const toggleTouching = (): void => {
        isTouching.current = !isTouching.current;
    }


    useEffect(() => {
        const map = mapRef.current!;
        if (!map || !isSingleTest) { return; }

        map.on('touchstart', toggleTouching)
        map.on('touchend', toggleTouching)
        map.on('move', onInteraction);
        map.on('zoom', onInteraction);

        return () => {
            removeMarker()
            map.off('touchstart', toggleTouching)
            map.off('touchend', toggleTouching)
            map.off('move', onInteraction);
            map.off('zoom', onInteraction);
        }

    }, [mapRef.current, isSingleTest]);

    useEffect(() => {

        removeMarker();
        if ((!prevCoords || coords[0] === null) && (!popup.changed && !isSingleTest)) {
            return;
        }

        addMarker(isSingleTest ? popup.coords as MapMarkerTypes.Coords : coords as MapMarkerTypes.Coords)
        setMarker(true);
        mapRef.current?.getZoom();

        return () => {
            removeMarker();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coords, popup, isSingleTest]);

    useEffect(() => {
        !popup.changed && removeMarker();
    }, [popup.changed]);

    return null;
}

export default memo(MapMarker);