import { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';

import { useMapActions } from '@hooks/useMapActions/useMapActions';

import { IUseMap, IUseMapReturn } from './useMap.interfaces';
import { init, destroy } from './useMap.utils';

import secrets from '@secrets';


export const useMap = ({ containerRef, onLoad, options }: IUseMap): IUseMapReturn => {

    const mapRef = useRef<mapboxgl.Map | null>(null);
    const dataRef = useRef<any>(null);

    const MAP_ACTIONS = useMapActions();



    useEffect(() => {
        MAP_ACTIONS.setLoaded(false);
        init({
            mapRef,
            containerRef,
            options,
            token: secrets.mapboxToken!,
            onLoad: () => {
                MAP_ACTIONS.setLoaded(true);
                onLoad!()
            }
        });

        return () => {
            MAP_ACTIONS.setLoaded(false);
            destroy(mapRef, containerRef);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return {
        mapRef,
        dataRef
    };
}