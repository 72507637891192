import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MapSliceTypes } from '@redux/types/map.types';
import { InternMap } from 'd3-array';
import { IPoints } from '@components/organisms/Map/Map.types';


export const initialState: MapSliceTypes.InitialState = {
    current: {
        coords: [null, null],
        name: '',
        id: 37951,
        type: 'country',
    },
    currentCoords: {
        coords: [null, null],
        name: '',
        id: 37951,
        type: 'country',
        bbox: [
            [-97.239209, 43.499356],
            [-97.239209, 49.384358],
            [-89.491739, 49.384358],
            [-89.491739, 43.499356]
        ]
    },
    currentLayer: {
        id: 'conus',
        name: 'Continental U.S.',
        nameDeposition: 'CONUS',
        isAvailable: true,
    },
    date: {
        start: null,
        end: null,
        min: null,
        max: null
    },
    isExtended: false,
    isVisible: true,
    isVisibleMobile: false,
    items: [],
    itemsExtended: [],
    itemsTotal: [],
    itemsTotalExtended: [],
    itemsFiltered: [],
    isLoaded: false,
    isSingleTest: false,
    isMarkerVisible: false,
    isFiltersVisible: false,
    toggleDropdown: false,
    layers: [
        {
            id: 'conus',
            name: 'Continental U.S.',
            title: 'Name',
            nameDeposition: 'CONUS',
            isAvailable: false,
        },
        {
            id: 'street',
            name: 'Street Address',
            title: 'Street Address',
            nameDeposition: 'Street Address',
            isAvailable: true,
        },
        {
            id: 'zip',
            name: 'Zip Code',
            title: 'Zip Code',
            nameDeposition: 'Zip Code',
            isAvailable: true,
        },
        {
            id: 'counties',
            name: 'Counties',
            title: 'County Name',
            nameDeposition: 'County',
            isAvailable: true,
        },
        {
            id: 'tribal',
            name: 'Federally Recognized Tribal Lands',
            title: 'Federally Recognized Tribal Land Name',
            nameDeposition: 'Tribal Land',
            isAvailable: true,
        },
        {
            id: 'states',
            name: 'States',
            title: 'State Name',
            nameDeposition: 'State',
            isAvailable: true,
        }
    ],
    filters: {
        isLoaded: false,
        range: {
            min: null,
            max: null
        },
        min: {
            day: 0,
            month: 0,
            year: 0,
            hour: 0,
        },
        max: {
            day: 0,
            month: 0,
            year: 0,
            hour: 23,
        }
    },
    range: {
        min: 0,
        max: 217,
        difference: 217,
    },
    rangeGrid: {
        min: 3.6200318951262975,
        max: 12.978793956278615,
    },
    rangeGridInit: {
        min: 3.6200318951262975,
        max: 12.978793956278615,
    },
    calendar: {
        min: {
            day: 0,
            month: 0,
            year: 0,
            hour: 0,
        },
        max: {
            day: 0,
            month: 0,
            year: 0,
            hour: 23,
        },
        isChanged: false,
        isSet: false
    },
    sortedData: null,
    currentTime: {
        period: [],
        date: {
            hour: '',
            date: ''
        },
        pollution: 1000,
        color: 'red',
        periodIndex: 1,
    },
    inputRangeValue: 0,
    isAutoplay: false,
    currentSingleTest: null,
    mapState: {
        zoom: 3.5,
        lat: 38,
        lon: -95,
    },
    popup: {
        location: '',
        volume: 0,
        color: 'red',
        changed: false,
        coords: [null, null]
    },
    points: {
        x: 0,
        y: 0,
        lng: 0,
        lat: 0
    },
    triggerMapClickMobile : false,
    canZoom: false,
    gridFeatures: [],
    gridDepositions: {
        grid_cell_depositions: [],
        deposition_25_perc: 0,
        deposition_75_perc: 0,
        deposition_max: 0,
        deposition_min: 0,
        deposition_weighted_avg: 0,
    },
    triggerReset: false,
};


const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        updatePoints(state, action: PayloadAction<IPoints>) {
            state.points = {
                ...action.payload
            }
        },
        updatePopup(state, action: PayloadAction<MapSliceTypes.IPopup>) {
            state.popup = {
                ...action.payload,
                changed: action.payload.changed
            }
        },
        updateCanZoom(state, action: PayloadAction<boolean>) {
            state.canZoom= action.payload;
        },
        updatePopupValues(state, action: PayloadAction<MapSliceTypes.IPopupValues>) {
            state.popup.volume = action.payload.volume;
            state.popup.color = action.payload.color;
        },
        updateDropdown: (state, action: PayloadAction<boolean>) => {
            state.toggleDropdown = action.payload;
        },
        updateMarker: (state, action: PayloadAction<boolean>) => {
            state.isMarkerVisible = action.payload;
        },
        updateCurrent: (state, action: PayloadAction<MapSliceTypes.CurrentItem>) => {
            state.current = action.payload;
        },
        updateCurrentCoords: (state, action: PayloadAction<MapSliceTypes.CurrentItem>) => {
            state.currentCoords = action.payload;
        },
        updateLayer: (state, action: PayloadAction<MapSliceTypes.Layer>) => {
            state.currentLayer = action.payload;
        },
        updateExtended: (state, action: PayloadAction<boolean>) => {
            state.isExtended = action.payload;
        },
        updateVisible: (state, action: PayloadAction<boolean>) => {
            state.isVisible = action.payload;
        },
        updateVisibleMobile: (state, action: PayloadAction<boolean>) => {
            state.isVisibleMobile = action.payload;
        },
        updateFiltersVisible: (state, action: PayloadAction<boolean>) => {
            state.isFiltersVisible = action.payload;
        },
        updateLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        updateLayers: (state, action: PayloadAction<MapSliceTypes.Layer[]>) => {
            state.layers = action.payload;
        },
        updateItems: (state, action: PayloadAction<MapSliceTypes.ApiDataItem[]>) => {
            state.items = action.payload;
        },
        updateItemsExtedned: (state, action: PayloadAction<MapSliceTypes.ApiDataItem[]>) => {
            state.itemsExtended = action.payload;
        },
        updateItemsTotal: (state, action: PayloadAction<MapSliceTypes.ApiDataItem[]>) => {
            state.itemsTotal = action.payload;
        },
        updateItemsTotalExtended: (state, action: PayloadAction<MapSliceTypes.ApiDataItem[]>) => {
            state.itemsTotalExtended = action.payload;
        },
        updateFiltersCurrentDateMax: (state, action: PayloadAction<MapSliceTypes.FilterItem>) => {
            state.filters.max = action.payload;
        },
        updateFiltersCurrentDateMin: (state, action: PayloadAction<MapSliceTypes.FilterItem>) => {
            state.filters.min = action.payload;
        },
        updateFiltersRange: (state, action: PayloadAction<MapSliceTypes.FilterRange>) => {
            state.filters.range = action.payload;
        },
        updateGridRange: (state, action: PayloadAction<MapSliceTypes.GridRange>) => {
            state.rangeGrid = action.payload;
        },
        updateFiltersLoaded: (state, action: PayloadAction<boolean>) => {
            state.filters.isLoaded = action.payload;
        },
        updateItemsFiltered: (state, action: PayloadAction<MapSliceTypes.ApiDataItem[]>) => {
            state.itemsFiltered = action.payload;
        },
        updateCalendarCurrentDateMax: (state, action: PayloadAction<MapSliceTypes.FilterItem>) => {
            state.calendar.max = action.payload;
        },
        updateCalendarCurrentDateMin: (state, action: PayloadAction<MapSliceTypes.FilterItem>) => {
            state.calendar.min = action.payload;
        },
        toggleCalendarClick: (state, action: PayloadAction<boolean>) => {
            state.calendar.isChanged = action.payload;
        },
        toggleCalendarSet: (state, action: PayloadAction<boolean>) => {
            state.calendar.isSet = action.payload;
        },
        updateRangeLoaded: (state, action: PayloadAction<boolean>) => {
            state.filters.isLoaded = action.payload;
        },
        updateSliderRange: (state, action: PayloadAction<{min:number,max:number,difference: number}>) => {
            state.range = action.payload;
        },
        updateSingleTest: (state, action: PayloadAction<boolean>) => {
            state.isSingleTest = action.payload;
        },
        updateData(state, action: PayloadAction<InternMap<any, GeoJSON.Feature[]>>) {
            state.sortedData = action.payload;
        },
        updateCurrentTime(state, action: PayloadAction<MapSliceTypes.CurrentTime>) {
            state.currentTime = action.payload;
        },
        updateInputRangeValue(state, action: PayloadAction<number>) {
            state.inputRangeValue = action.payload;
        },
        updateAutoplay(state, action: PayloadAction<boolean>) {
            state.isAutoplay = action.payload;
        },
        updateCurrentTest(state, action: PayloadAction<MapSliceTypes.ApiDataItem | null>) {
            state.currentSingleTest = action.payload;
        },
        updateMapState(state, action: PayloadAction<MapSliceTypes.InitialState['mapState']>) {
            state.mapState = action.payload;
        },
        updateMapClickMobile(state, action: PayloadAction<boolean>) {
            state.triggerMapClickMobile = action.payload;
        },
        updateGridFeatures(state, action: PayloadAction<any[]>) {
            state.gridFeatures = action.payload;
        },
        updateGridDepositions(state, action: PayloadAction<MapSliceTypes.IGridDepositions>) {
            state.gridDepositions = action.payload;
        },
        updateTriggerReset(state, action: PayloadAction<boolean>) {
            state.triggerReset = action.payload;
        }
    }
});


export default mapSlice;