import { Link } from 'react-router-dom';

import { IButton } from './Button.interfaces';

import ViewfinderIcon from '@components/atoms/ViewfinderIcon/ViewfinderIcon';
import { ReactComponent as SVGList } from '@assets/svg/list.svg';
import { ReactComponent as SVGGlobe } from '@assets/svg/globe.svg';
import { ReactComponent as SVGFilters } from '@assets/svg/filters.svg';


const Button = ({ icon, route, onClick, text, type = 'normal' }: IButton) => {

    const isRoute = !!route;

    return (<>
        {
            isRoute ? <Link className={`button${icon ? ` button--${icon}` : ''}${type !== 'normal' ? ` button--${type}` : ''}`} to={route}>
                <span>{text}</span>
            </Link> : 
            <button className={`button${icon ? ` button--${icon}` : ''}${type !== 'normal' ? ` button--${type}` : ''}`} onClick={onClick! ?? null}>
                <span>{text}</span>
            </button>
        }
    </>
    )
};

export default Button;