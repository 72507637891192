import { Expression } from "mapbox-gl"

interface IMapLayerData {
    [key: string]: Expression
}

export const mapLayerData: IMapLayerData = {
    plasma: [
              "interpolate", ["linear"],
              ["get", "deposition_weighted_avg"],
              15814.093794414986,
              "rgb(13, 8, 135)",
              38868.184831444356,
              "rgb(44, 5, 149)",
              95530.97456806933,
              "rgb(67, 3, 158)",
              234797.87238590163,
              "rgb(90, 1, 165)",
              577090.7407383766,
              "rgb(110, 0, 169)",
              1418384.7564794424,
              "rgb(131, 5, 168)",
              3486133.419571361,
              "rgb(150, 18, 162)",
              8568285.977083879,
              "rgb(168, 33, 152)",
              21059298.58362069,
              "rgb(183, 48, 140)",
              51759950.35882644,
              "rgb(198, 64, 127)",
              127216604.60391055,
              "rgb(210, 79, 115)",
              312675425.2033004,
              "rgb(222, 95, 102)",
              768499692.5555382,
              "rgb(232, 110, 91)",
              1888833371.135431,
              "rgb(241, 127, 79)",
              4642411101.103994,
              "rgb(248, 144, 68)",
              11410207570.982393,
              "rgb(253, 164, 56)",
              28044228306.69676,
              "rgb(255, 183, 45)",
              68927645393.43208,
              "rgb(253, 206, 38)",
              169411696678.71414,
              "rgb(248, 227, 37)",
              416383336580.5867,
              "rgb(241, 250, 34)"
            ],
    turbo: [
        "interpolate",
        ["linear"],
        ["get", "deposition_weighted_avg"],
              15814.093794414986,
              "rgb(49, 18, 59)",
              38868.184831444356,
              "rgb(62, 56, 146)",
              95530.97456806933,
              "rgb(69, 92, 208)",
              234797.87238590163,
              "rgb(71, 128, 247)",
              577090.7407383766,
              "rgb(60, 161, 254)",
              1418384.7564794424,
              "rgb(36, 195, 229)",
              3486133.419571361,
              "rgb(24, 222, 195)",
              8568285.977083879,
              "rgb(44, 241, 159)",
              21059298.58362069,
              "rgb(90, 252, 115)",
              51759950.35882644,
              "rgb(143, 256, 73)",
              127216604.60391055,
              "rgb(181, 249, 54)",
              312675425.2033004,
              "rgb(215, 230, 54)",
              768499692.5555382,
              "rgb(240, 206, 58)",
              1888833371.135431,
              "rgb(254, 175, 53)",
              4642411101.103994,
              "rgb(254, 138, 38)",
              11410207570.982393,
              "rgb(243, 97, 20)",
              28044228306.69676,
              "rgb(225, 65, 9)",
              68927645393.43208,
              "rgb(198, 38, 3)",
              169411696678.71414,
              "rgb(164, 19, 1)",
              416383336580.5867,
              "rgb(123, 4, 3)"
            ],
    plasma_r: [
          "interpolate",
          ["linear"],
          ["get", "deposition_weighted_avg"],
          15814.093794414986,
          "rgb(241, 250, 34)",
          38868.184831444356,
          "rgb(248, 227, 37)",
          95530.97456806933,
          "rgb(253, 206, 38)",
          234797.87238590163,
          "rgb(255, 183, 45)",
          577090.7407383766,
          "rgb(253, 164, 56)",
          1418384.7564794424,
          "rgb(248, 144, 68)",
          3486133.419571361,
          "rgb(241, 127, 79)",
          8568285.977083879,
          "rgb(232, 110, 91)",
          21059298.58362069,
          "rgb(222, 95, 102)",
          51759950.35882644,
          "rgb(210, 79, 115)",
          127216604.60391055,
          "rgb(198, 64, 127)",
          312675425.2033004,
          "rgb(183, 48, 140)",
          768499692.5555382,
          "rgb(168, 33, 152)",
          1888833371.135431,
          "rgb(150, 18, 162)",
          4642411101.103994,
          "rgb(131, 5, 168)",
          11410207570.982393,
          "rgb(110, 0, 169)",
          28044228306.69676,
          "rgb(90, 1, 165)",
          68927645393.43208,
          "rgb(67, 3, 158)",
          169411696678.71414,
          "rgb(44, 5, 149)",
          416383336580.5867,
          "rgb(13, 8, 135)"
        ]
}
