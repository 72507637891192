import { useRef } from 'react';


import { ReactComponent as SVGLogotype } from '@svg/logotype.svg';


const LogoIcon = () => {

    const ref = useRef<SVGSVGElement>(null);


    return (
        <SVGLogotype ref={ref} />
    );
}

export default LogoIcon;