import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UnviversalSliceTypes } from '@redux/types/universal.types';

const initialState: UnviversalSliceTypes.InitialState = {
    isMenuOpen: false,
    isHeaderVisible: true,
    isMapLoaded: false,
    isGridLoaded: false,
    howToFlag: false,
    isAboutOpen: true,
    forceZoom: false,
    forceZoomAddress: false,
    forceZoomConus: false,
    isModalVisible: true,
    isLivesearchClicked: false,
    order: 'deposition',
    orderList: 'date',
    isAutoFocus: false,
    clearSearchInput: false,
};

const universalSlice = createSlice({
    name: 'universal',
    initialState,
    reducers: {
        toggleMenu(state) {
            state.isMenuOpen = !state.isMenuOpen;
        },
        clearSearchInput(state) {
            state.clearSearchInput = !state.clearSearchInput;
        },
        setHeaderVisible(state, action: PayloadAction<boolean>) {
            state.isHeaderVisible = action.payload;
        },
        setModalVisible(state, action: PayloadAction<boolean>) {
            state.isModalVisible = action.payload;
        },
        closeMenu(state) {
            state.isMenuOpen = false;
        },
        setHowToFlag(state) {
            state.howToFlag = true;
        },
        setMapLoaded(state) {
            state.isMapLoaded = true;
        },
        setGridLoaded(state) {
            state.isGridLoaded = true;
        },
        setAboutOpen(state) {
            state.isAboutOpen = !state.isAboutOpen;
        },
        updateOrder(state, action: PayloadAction<UnviversalSliceTypes.InitialState['order']>) {
            state.order = action.payload;
        },
        updateOrderList(state, action: PayloadAction<UnviversalSliceTypes.InitialState['orderList']>) {
            state.orderList = action.payload;
        },
        setForceZoom(state) {
            state.forceZoom = !state.forceZoom;
        },
        setForceZoomAddress(state) {
            state.forceZoomAddress = !state.forceZoomAddress;
        },
        setForceZoomConus(state, action?:PayloadAction<boolean>) {
            state.forceZoomConus = action?.payload ?? !state.forceZoomConus;
        },
        updateLiveSearchClicked(state, action: PayloadAction<boolean>) {
            state.isLivesearchClicked = action.payload;
        },
        updateAutoFocus(state, action: PayloadAction<boolean>) {
            state.isAutoFocus = action.payload;
        },
    }
});

export default universalSlice;