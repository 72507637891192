const Grid = (): JSX.Element => {

    return (
        <div className='mapgrid'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div className='desktop-only'></div>
            <div className='desktop-only'></div>
            <div className='desktop-only'></div>
            <div className='desktop-only'></div>
            <div className='desktop-only'></div>
            <div className='desktop-only'></div>
            <div className='desktop-only'></div>
            <div className='desktop-only'></div>
            <div className='desktop-only'></div>
            <div className='desktop-only'></div>
            <div className='desktop-only'></div>
            <div className='desktop-only'></div>
            <div className='desktop-only'></div>
            <div className='desktop-only'></div>
            <div className='desktop-only'></div>
        </div>
    );
}

export default Grid;