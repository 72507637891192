/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, memo, useState, useMemo } from 'react';
import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import gsap from 'gsap';
import { InternMap } from 'd3-array';
import 'mapbox-gl/dist/mapbox-gl.css';

import MapMarker from '@components/atoms/MapMarker/MapMarker';
import MapLayers from '@components/atoms/MapLayers/MapLayers';
import MapDepositions from '@components/atoms/MapDepositions/MapDepositions';

import { useMap } from '@hooks/useMap/useMap';
import { useMapData } from '@hooks/useMapData/useMapData';
import { useUniversalActions } from '@hooks/useUniversalActions/useUniversalActions';
import { useMapMethods } from '../../../hooks/useMapMethods/useMapMethods';
import { useUniversalData } from '@hooks/useUniversalData/useUniversalData';
import { useMapActions } from '../../../hooks/useMapActions/useMapActions';
import { useBreakpoint } from '@hooks/useBreakpoint/useBreakpoint';
import { controls, sortData } from '@hooks/useMap/useMap.utils';

import { IMapProps } from './Map.types';

import MapZoom from '@components/atoms/MapZoom/MapZoom';
import Grid from '@components/scaffold/Grid/Grid';
import BorderTooltip from '@components/atoms/BorderTooltip/BorderTooltip';

import { ReactComponent as HelpSvg } from '@assets/svg/help-icon.svg';

import { mobileZoom } from '@data/map';
import { useGetPlumesQuery } from '@redux/services/plumes';
import { pad } from '@utils/pad';
// import secrets from '../../../secrets/index';

const Map = ({ lng = -95, lat = 38, zoom = 4.5, zoomInitPanel = 3.25 }: IMapProps) => {

    const containerRef = useRef<HTMLDivElement>(null);
    const timelineRef = useRef(gsap.timeline({ repeat: -1, paused: true }));
    const animationRef = useRef({ value: 0 });
    const {
        currentCoords,
        isSingleTest,
        isExtended,
        isLoaded,
        current,
        sortedData,
        currentTime,
        isAutoplay,
        inputRangeValue,
        isMarkerVisible,
        isVisible,
        isVisibleMobile,
        currentSingleTest,
        points,
        triggerMapClickMobile
        // canZoom,
    } = useMapData(({ map }) => map);

    const { forceZoom, forceZoomAddress, forceZoomConus } = useUniversalData();
    const { mapRef } = useMap({
        containerRef,
        options: { lng, lat, zoom: zoom, zoomInitPanel },
        onLoad: () => {
            setMapLoaded();
        }
    });

    const { data: dataPlumes, isSuccess: isSuccessPlumes } = useGetPlumesQuery({ id: (currentSingleTest ? pad(currentSingleTest.test_number, 6) : '')});


    const [hideLayers, triggerhideLayers] = useState(false);
    const [restoreLayers, triggerRestoreLayers] = useState(false);
    const { applyClick, applyPopup, applyHeatmap, applyTimeline, updateHeatmap, applyAutoplay, updatePopupVolume } = useMapMethods({ mapRef, timelineRef, animationRef, sortedData, triggerMapClickMobile });

    const { desktop } = useBreakpoint();

    zoomInitPanel = !desktop ? 2 : zoomInitPanel;

    const { setMapLoaded, } = useUniversalActions();
    const { setSortedData, setAutoplay, setInputRangeValue, setCanZoom } = useMapActions();

    const onSingleClick = useMemo(() => (e: any) => {
        applyPopup(e);
    }, []);


    const flyTo = (type: string, coords: mapboxgl.LngLatLike, bbox?: number[][]) => {
        const map = mapRef.current;
        let zoom = 6;

        switch (type) {
            case 'state':
                zoom = 6;
                break;

            case 'county':
                zoom = 8;
                break;

            case 'tribal_land':
                zoom = 10;
                break;

            default:
                zoom = 11;
                break;
        }
        if (bbox) {
            map?.fitBounds([bbox[0], bbox[2]] as LngLatBoundsLike, {
                padding: 50
            });
        } else {
            map?.flyTo({
                center: coords,
                zoom,
            });
        }
    }


    const updateSortedData = (data: any) => {
        const f: InternMap<any, GeoJSON.Feature[]> | null = sortData(data);
        setSortedData(f);
    }


    const setMobileZoom = (zoomIn?: boolean): void => {
        document.body.classList.toggle('is-mobile-zoomed', zoomIn);
        mapRef.current?.resize();
    }


    const zoomIn = (): void => {
        const map = mapRef.current!;

        setCanZoom(true);
        setMobileZoom(true);

        map.flyTo({
            center: map.getCenter(),
            zoom: mobileZoom,
        });
    }


    const onResize = (): void => {
        if (mapRef.current) {
            mapRef.current.resize();

            mapRef.current.flyTo({
                center: [-95, 38],
                zoom: 1,
            });
        }
    }


    const toggleMapInteractions = (enable?: boolean): void => {
        // if (desktop) { return; }
        const map = mapRef.current!;
        if (enable) {
            map.dragPan.enable();
            map.scrollZoom.enable();
            map.doubleClickZoom.enable();
            map.touchZoomRotate.enable();
        } else {
            map.dragPan.disable();
            map.doubleClickZoom.disable();
            map.scrollZoom.disable();
            map.touchZoomRotate.disable();
        }
    }


    useEffect(() => {
        containerRef.current?.classList.toggle('is-visible', desktop ? isVisible : isVisibleMobile);
    }, [isVisible, isVisibleMobile, desktop]);


    useEffect(() => {
        if (!isLoaded) return;

        const duration = isExtended ? 3200 : 2500;
        const map = mapRef.current;

        document.body.classList.toggle('is-map-extended', isExtended);
        document.body.classList.toggle('is-map-usable', isExtended);

        map?.flyTo({
            center: [-95, 38],
            zoom: isExtended ? zoomInitPanel : zoom,
            duration
        });

        containerRef.current!.appendChild(controls.onAdd((window as any).map));

        isExtended ? triggerRestoreLayers(!restoreLayers) : triggerhideLayers(!hideLayers);

    }, [isLoaded, isExtended])


    useEffect(() => {
        if (currentCoords.coords[0] === null) { return }
        !desktop && setMobileZoom(true);
        setCanZoom(true);
        flyTo(currentCoords.type!, currentCoords.coords as mapboxgl.LngLatLike, currentCoords.bbox);
    }, [forceZoom]);


    useEffect(() => {
        if (current.coords[0] === null) { return }
        !desktop && setMobileZoom(true);
        setCanZoom(true);
        flyTo(current.type!, current.coords as mapboxgl.LngLatLike);
    }, [forceZoomAddress]);


    useEffect(() => {
        const map = mapRef.current;

        if (desktop) {
            map?.flyTo({
                zoom: zoomInitPanel,
                center: [lng, lat],
            });
        } else {
            setMobileZoom();
            map?.flyTo({
                zoom: (forceZoomConus && isSingleTest) ? 4 : zoomInitPanel,
                center: [lng, lat],
            })
        }
    }, [forceZoomConus]);


    useEffect(() => {
        if (!isLoaded) { return }
        const map = mapRef.current!;
        if (!map) { return }

        setCanZoom(false);

        if (isSingleTest) {
            setMobileZoom(false);
            map?.flyTo({
                center: [-95, 38],
                zoom: 1,
            });

            toggleMapInteractions();
            triggerhideLayers(!hideLayers);
            timelineRef.current.progress(0);
            setInputRangeValue(0);
            map.on('click', onSingleClick);
        } else {
            map.off('click', onSingleClick);
            map.setLayoutProperty('heatmap', 'visibility', 'none');
            triggerRestoreLayers(!restoreLayers);
            toggleMapInteractions(true);
        }
    }, [isSingleTest, isLoaded, currentSingleTest]);


    useEffect(() => {
        if (!isLoaded) { return }
        if (!sortedData) { return }
        applyTimeline(sortedData!.size);
    }, [sortedData])


    useEffect(() => {
        if (!isSuccessPlumes) { return }
        if (!currentSingleTest) { return }

        const map = mapRef.current!;
        if (!map) { return }
        if (dataPlumes!.features[0].properties!.TEST_NAME !== pad(currentSingleTest!.test_number, 6)) { return }
        applyHeatmap(dataPlumes).then(newData => {
            const source = map.getSource('heatmap') as mapboxgl.GeoJSONSource;

            document.body.classList.remove('is-plumes-loading');

            source && source.setData(newData);
            updateSortedData(newData)
            setAutoplay(true);
            map.setLayoutProperty('heatmap', 'visibility', 'visible');
        });
    }, [isSuccessPlumes, dataPlumes])


    /**
     *  Update heatmap with period's change
     */
    useEffect(() => {

        updateHeatmap(currentTime.period);
        isMarkerVisible && updatePopupVolume(points)
        // updateCurrent(currentTime.periodIndex);

    }, [currentTime]);


    /**
     * Handle resize
     */
    useEffect(() => {
        window.addEventListener('resize', onResize);

        return (() => {
            window.removeEventListener('resize', onResize);
        })
    }, []);


    /**
     *  Handle Autoplay
     */
    useEffect(() => {
        if (!isLoaded) { return }
        applyAutoplay({ timelineRef, isAutoplay, inputRangeValue });
    }, [isAutoplay]);

    return (
        <div className={`map${isSingleTest ? ' is-single' : ''}`} id='nuclear-map'>
            <Grid />
            <div ref={containerRef} className='map__wrap'>
            </div>
            {isSingleTest &&
                <div className='map__frame'>
                    {!desktop && <div className='map__border'><BorderTooltip classModifier='--button-only'/></div>}
                    <div className='map__days'>5 Days of deposition per test {desktop && <HelpSvg />}</div>
                    <div className='map__model'>
                        {desktop ? <BorderTooltip copy='Modeled area' popupPosition='up' popupTitle='What is Modeled Area?' popupCopy='Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Aenean lacinia bibendum nulla sed consectetur. Donec sed odio dui. Nullam id dolor id nibh ultricies vehicula ut id elit.'/> : 'Modeled area'}
                    </div>
                </div>
            }
            <div className='map__loader'>
                <svg className='svg-loader' viewBox="0 0 60 60"><circle cx="30" cy="30" r="29" fill="none" stroke="#fff"></circle></svg>
            </div>
            {isLoaded && (
                <>
                    <MapMarker mapRef={mapRef} />
                    <MapLayers applyClick={applyClick} triggerRestoreLayers={restoreLayers} triggerHideLayers={hideLayers} mapRef={mapRef} />
                    <MapDepositions />
                    <MapZoom mapRef={mapRef} />
                </>
            )}
            {!desktop && <button onClick={zoomIn} className='map__info'><span>Zoom to explore</span></button>}
        </div>
    )

}



export default memo(Map);
