import { NavLink } from 'react-router-dom';

import Toggle from '@components/molecules/Toggle/Toggle';
import LogoIcon from '@components/atoms/LogoIcon/LogoIcon';
import { useUniversalData } from '@hooks/useUniversalData/useUniversalData';

const Header = () => {

    const { isHeaderVisible } = useUniversalData()

    const toggle = () => {
        document.body.classList.toggle('is-menu-open');
    }

    return (
        <header className={`header${!isHeaderVisible ? ' is-hidden' : ''}`} id="header">
            <NavLink to='/' className='header__logo'>
                <LogoIcon />
                <span className='sr-only'>go to the homepage</span>
            </NavLink>
            <Toggle onClick={toggle} />
            <div className='header__zone js-hidden-zone'></div>
        </header>
    )
}


export default Header; 