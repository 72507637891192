/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useGetDataQuery, useGetDataByIdQuery, useGetCoordsQuery, useGetAllMetadataQuery } from '@redux/services/map';
import { useGetGridDepositionsQuery } from '@redux/services/mapProd';

import { useMapData } from '@hooks/useMapData/useMapData';
import { useMapActions } from '@hooks/useMapActions/useMapActions';

import { formatData } from './MapDepositions.utils';
import { MapDepositionsTypes } from './MapDepositions.types';
import { useUniversalData } from '@hooks/useUniversalData/useUniversalData';
import { useUniversalActions } from '@hooks/useUniversalActions/useUniversalActions';


const MapDepositions = () => {

    const { current } = useMapData<MapDepositionsTypes.MapData>(({ map }) => map);
    const { data, isSuccess } = useGetDataQuery({ lon: current.coords[0], lat: current.coords[1] });
    const { data: dataById, isSuccess: isSuccessById } = useGetDataByIdQuery({ id: current.id, areaType: current.type });
    const { data: dataCoords, isSuccess: isSuccessCoords } = useGetCoordsQuery({ id: current.id, areaType: current.type });
    const { data: dataGridDepositions, isSuccess: isSuccessGridDepositions } = useGetGridDepositionsQuery({ id: current.id, type: current.type });
    const { data: dataAll, isSuccess: isSuccessAll } = useGetAllMetadataQuery();
    const { isLivesearchClicked } = useUniversalData();
    const { setForceZoom, setForceZoomAddress } = useUniversalActions();


    const MAP_ACTIONS = useMapActions();
    const {items, itemsTotal, itemsTotalExtended} = useMapData(({ map }) => map);

    const setZoom = (): void => {
        if (isSuccessCoords) {
                const bbox = (dataCoords.features[0] as any).properties.bbox[0].slice(0, 4);

            MAP_ACTIONS.setCurrentCoords({
                id: dataCoords.features[0].id as number,
                coords: (dataCoords.features[0].geometry as any).coordinates,
                name: (dataCoords.features[0] as any).properties.name,
                type: current.type,
                bbox
            });

            if (isLivesearchClicked) {
                setForceZoom();
            }
        }
    }

    useEffect(() => {
        document.body.classList.toggle('has-current-coord', isSuccess);
        // only for lat lon elements
        if (isSuccess) {
            MAP_ACTIONS.setItems(formatData(data));
            if (isLivesearchClicked) {
                setForceZoomAddress();
            }
        }

    }, [isSuccess, data]);

    useEffect(() => {
        if (isSuccessById) {
            // for /list page get total items only on init
            if (itemsTotal.length === 0) {
                MAP_ACTIONS.setItemsTotal(formatData(dataById));
            }
            MAP_ACTIONS.setItems(formatData(dataById));
        }
        if (current.type !== 'address') {
            document.body.classList.toggle('has-current-coord', isSuccessById);
        }

    }, [dataById, isSuccessById]);


    useEffect(() => {
        setZoom();
    }, [dataCoords, isSuccessCoords]);

    useEffect(() => {
        if (itemsTotal.length > 0 && isSuccessAll) {
            const extendedItems = itemsTotal.map(item => {
                const filteredMetadata = dataAll.filter(singleMetadata => singleMetadata.test_number === item.test_number)[0];

                const tempObj = Object.assign({}, item, {
                    yield: filteredMetadata?.yield,
                    test_type: filteredMetadata?.type,
                    height: filteredMetadata?.height})
                    return tempObj;
                });
            MAP_ACTIONS.setItemsTotalExtended(extendedItems);
        }

    }, [dataAll, isSuccessAll, itemsTotal]);


    useEffect(() => {
        if (items.length > 0 && itemsTotalExtended.length > 0) {
            const extendedItems = items.map(item => {
                const filteredMetadata = itemsTotalExtended.filter(singleMetadata => singleMetadata.test_number === item.test_number)[0];
                const tempObj = Object.assign({}, item, {height: filteredMetadata ? filteredMetadata.height : 0, yield: filteredMetadata ? filteredMetadata.yield : 0, test_type: filteredMetadata ? filteredMetadata.test_type : ''})
                return tempObj;
            });

            MAP_ACTIONS.setItemsExtedned(extendedItems);
        }
    }, [items, itemsTotalExtended]);


    useEffect(() => {
        if (dataGridDepositions) {
            MAP_ACTIONS.setGridDepositions(dataGridDepositions);
        }
    }, [dataGridDepositions, isSuccessGridDepositions]);

    return null;
}

export default MapDepositions;