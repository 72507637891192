import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';

import { UnviversalSliceTypes } from '@redux/types/universal.types';

export const useUniversalData = () => {

    const data = useSelector<RootState, UnviversalSliceTypes.InitialState>(({ universal }) => universal);

    return data;

}